import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import { CustomTabPanel } from "components";
import { DashboardLayout } from "layouts";
import React from "react";

import LockPersonIcon from "@mui/icons-material/LockPerson";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import {
  ChangePasswordSection,
  NotificationSettingsSection,
  AggrementsSection,
  AccountSection,
} from "sections";
import { AccountBox } from "@mui/icons-material";
import { useAppSelector } from "context";

export const CandidateSettingsPage = () => {
  const [value, setValue] = React.useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const isMobileApp = urlParams.get("platform") === "mobile-app";

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <Typography variant="h1">Ayarlar</Typography>

      {/* <Box bgcolor={"secondary.lighter"} p={4} mt={2} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                <Box width={"20px"} height={"18px"} mr={2} color={"primary.main"}>
                    <Iconify icon={"material-symbols:info-outline"} />
                </Box>
                <Typography variant="body2" >
                    Bu sayfada referanslarınızı görebilir ve referans ekleyebilirsiniz. Eklediğiniz referansları özgelecek oluştururken kullanabilirsiniz. Referans onayı gerçekleştiğinde ilanlara başvurabilirsiniz.
                    (Maksimum 5 referans eklenebilir)
                </Typography>
            </Box> */}

      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon position tabs example"
        textColor="primary"
        indicatorColor="primary"
        sx={{
          my: 3,
        }}
      >
        <Tab icon={<LockPersonIcon />} iconPosition="start" label="Şifre" />
        <Tab icon={<SettingsIcon />} iconPosition="start" label="Bildirimler" />
        <Tab icon={<ArticleIcon />} iconPosition="start" label="Sözleşmeler" />
        {isMobileApp && (
          <Tab icon={<AccountBox />} iconPosition="start" label="Hesap" />
        )}
      </Tabs>

      <CustomTabPanel value={value} index={0}>
        <ChangePasswordSection />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <NotificationSettingsSection />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <AggrementsSection />
      </CustomTabPanel>

      {isMobileApp && (
        <CustomTabPanel value={value} index={3}>
          <AccountSection />
        </CustomTabPanel>
      )}
    </DashboardLayout>
  );
};
